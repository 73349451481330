.mainLogoBar__logo > a {
  @include breakpoint(smallUp) {
    height: 70%;
  }
}

// Slider strona glowna prezydent
.servicesPromoBox .splide.arrowsLight .splide__arrow {
  &:hover:before {
    color: $c-white;
  }

  &:before {
    color: $c-east-bay;
  }
}

.servicesPromoBox .socialMedia__lnk .icon {
  color: $c-kimberly;
}

.servicesPromoBox .rightSide__content .lnkBtn {
  border-color: $c-kimberly;
}

.splide .slideNumber {
  @include breakpoint(mobile) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

// Ukrycie reklamy na mobile - mainNews aside
.mainNews {
  .aside {
    .advertisement {
      @include breakpoint(tabletmobile) {
        display: none;
      }
    }
  }
}

// Ukrycie reklamy na desktop - pod sekcja komunikacja
.horizontalNewsListing + .bigAdBox.commercial-1 {
  @include breakpoint(mobileUp) {
    display: none;
  }
}

.promoBoxSliderSection.dark {
  .editAcmsSection {
    display: none;
  }
}

// ukrycie sekcji [iconSlider - Informacje praktyczne]
.iconSlider {
  @include breakpoint(phonesDown) {
    display: none;
  }
}

// Responsywność
@include breakpoint(tabletmobile) {
  // Ukrycie części reklam na tablet i mobile na homepage
  .newsletter + .bigAdBox,
  .servicesPromoBox + .bigAdBox,
  .promoBoxSliderSection.dark + .bigAdBox {
    display: none;
  }
}
