// SCSS Color Variables
// Named with this: http://chir.ag/projects/name-that-color

$c-black: #000000;
$c-white: #FFFFFF; 

$c-astronaut: #2B2D77;
$c-east-bay: #9B9EBF;
$c-holly: #001209;
$c-kimberly: #9B9EBF;
$c-mischka: #CED3DA;