// Main
$body-color: $c-holly;
$main-font: 'Fira Sans';

:root {
  --promo-box-slider-bg-light: #1E68A7;
  --firstColorCityQuide: #E51553;
  --secondColorCityQuide: #E51553;
  --thirdColorCityQuide: #E51553;
  // --main-color: #{$c-denim}; 
  // --main-color-dark: #{$c-tory-blue}; 
  // --main-color-light: #{$c-picton-blue};
  // --details-color: #{$c-denim}; 
  // --promo-box-slider-bg: #{$c-atoll}; 
  // --promo-box-slider-sign-color: #{$c-malibu};
  // --box-data-bg: #{$c-atoll};  
  // --services-promo-box-bg: #{$c-astronaut};
  // --services-promo-box-details-color: #{$c-white};  
}